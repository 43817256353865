import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PhoneNumberLink from './Component/PhoneNumberLink';
import './App.css';
import ConcerntLink from './Component/ConcerntLink';
import ConcerntLinkUBL from './Component/ConcentLinkUBL';
import ConcerntLinkOMPL from './Component/ConcentLinkOMPL';
import ThankYou from './Component/ThankYou';

const App = () => {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/phone/:text" element={<PhoneNumberLink />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/consentUCL/:data" element={<ConcerntLink />} />
          <Route path="/consentUBL/:data" element={<ConcerntLinkUBL />} />
          <Route path="/consentPL/:data" element={<ConcerntLinkOMPL />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
