import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./ConcentLinkOMPL.css";
import { Buffer } from "buffer";
import HeroFincorpLogo from "../assets/hero.png";
import { BaseUrl } from "../const";
 
const ConcerntLinkOMPL = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCheckedY, setIsCheckedY] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [parsedObject, setParsedObject] = useState(null);
  const { data } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
   
    if (localStorage.getItem("consentGiven") === "true") {
      navigate("/thankyou");
    }
  }, [navigate]);
 
  // const url =
  //   "https://partner-dev.api.sb.herofincorp.com/api/v1/bridge-app/leadgen/ompl/consent/status";
 
  const handleCheckboxChangeY = () => {
    setIsCheckedY(!isCheckedY);
  };
 
  useEffect(() => {
    try {
      const decodedString = Buffer.from(data, "base64").toString("utf-8");
      const parsedObject = JSON.parse(decodedString);
      setParsedObject(parsedObject);
      console.log("AppId : " + parsedObject.appid);
      console.log("FirstName : " + parsedObject.firstname);
      console.log("pan : " + parsedObject.pan);
      console.log("gender : " + parsedObject.gender);
      console.log("mobile : " + parsedObject.mobile);
    } catch (error) {
      console.log("error : " + error);
    }
  }, [data]);
 
  const handleSubmit = async () => {
    if (isCheckedY) {
      // setIsSubmitting(true);
 
      if (parsedObject?.expiry) {
        const expiryDate = new Date(
          parseInt(parsedObject.expiry, 10)
        ).getTime();
        const currentDate = Date.now();
        const timeDifference = currentDate - expiryDate;
        console.log(timeDifference, "timeDifference");
        console.log("expiryDate", "" + expiryDate);
        console.log("currentDate", "" + currentDate);
 
        if (timeDifference <= 180000) {
          // if (timeDifference <= 337788133 ) {
          console.log(timeDifference, "timeDifference");
          console.log("Token:", parsedObject.token);
          console.log("Lead ID:", parsedObject.appid);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",`Bearer ${parsedObject.token}`,
         
          );
          try{
 
          const raw = JSON.stringify({
            // leadId: "808015036",
            leadId: parsedObject?.appid,
            consentStatus: true,
          });
 
 
          console.log("Request Payload:", raw);
 
          const requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
 
          fetch(
            `${BaseUrl}/ompl/consent/status`,

            // "https://partner-dev.api.sb.herofincorp.com/api/v1/bridge-app/leadgen/ompl/consent/status",
            // "https://partner.api.herofincorp.com/api/v1/bridge-app/leadgen/ompl/consent/status",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
          navigate("/thankyou");
        }
          catch (error) {
              alert("Unable to submit the form! \n Please Try Again");
              console.error("Error:", error);
              setIsSubmitting(false);
            }
        } else {
          alert("Link has been expired now");
        }
      }
    }
  };
 
  const handleShowTerms = () => {
    setShowTerms(!showTerms);
  };
 
  if (!parsedObject) {
    return <div>Loading...</div>;
  }

  const firstName = parsedObject.firstname.split(" ")[0];
 
  return (
    <div className="container">
      <div className="form-container">
        <form className="full-page-form">
          <img
            src={HeroFincorpLogo}
            alt="Hero Fincorp Logo"
            className="App-logo"
          />
          <h1
            style={{
              textAlign: "center",
              marginBottom: "20px",
              position: "relative",
              fontSize: "20px",
            }}
          >
            Personal Loan Consent Form
            <span
              className="underline"
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                width: "82%",
                borderBottom: "2px solid black",
                bottom: "-2px",
              }}
            ></span>
          </h1>
          <div>
            <div className="form-group">
              <label htmlFor="name">Applicant’s Name:</label>
              <span className="underline" id="name">
                {parsedObject?.firstname}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="dateOfIncorporation">Date of Birth: </label>
              <span className="underline" id="dateOfIncorporation">
                {parsedObject?.dob}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="pan">PAN: </label>
              <span className="underline" id="pan">
                {parsedObject?.pan}
              </span>
            </div>
            <div className="form-group">
              {/* <label htmlFor="gender">Gender: </label>
              <span className="underline" id="gender">
                {parsedObject?.gender}
              </span> */}
              <div className="form-group">
              {parsedObject?.gender && (
                <>
                  <label htmlFor="gender">Gender: </label>
                  <span className="underline" id="gender">
                    {parsedObject.gender}
                  </span>
                </>
              )}
            </div>
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile: </label>
              <span className="underline" id="mobile">
                {parsedObject?.mobile}
              </span>
            </div>
          </div>
        </form>
      </div>
 
      <button
        onClick={handleShowTerms}
        style={{
          border: "none",
          background: "none",
          display: "flex",
          alignItems: "center",
          color: "green",
          marginTop: "10px",
        }}
      >
        <span style={{ marginRight: "5px", fontSize: "16px" }}>{">"}</span>
        <h2 style={{ margin: "0", color: "blue" }}>
          Declaration and Detailed Terms and Conditions
        </h2>
      </button>
      {showTerms && (
        <div>
          <p className="paragraph">
            I/We {firstName} hereby confirm and warrant that;
          </p>
          <div className="paragraph">
            <p>
              a) I/We am/are citizen(s) of India and that all the information
              furnished by me/us is true and accurate and up to date in all
              respects and that I/We have not withheld any information
              whatsoever. I/We authorize Hero FinCorp Limited ("HFCL”) to make
              any enquiries in this regard.
            </p>
            <p className="paragraph">
              b) I would like to know through telephonic calls, or SMS on my
              mobile number mentioned in the Loan Application Form as well as in
              this undertaking, or through any other communication mode, various
              HFCL Loan offer schemes or any other promotional schemes and
              hereby authorize HFCL, its employees, agent, associate to do so.
            </p>
            <p className="paragraph">
              c) I confirm that laws in relation to the unsolicited
              communication referred in "National Do Not Call Registry" (the
              NDNC Registry) as laid down by Telecom Regulatory Authority of
              India will not be applicable for such communication/calls/SMSs
              received from HFCL, its employees, agents and/or associates.
            </p>
            <p className="paragraph">
              d) I/We shall submit, sign, and execute all such loan agreements
              and other documents as may be prescribed and required by the HFCL
              at any point of time and the applicable stamp duty shall be
              payable by me/us.
            </p>
            <p>
              e) I/We fully acknowledge that the Application fees given by me is
              non-refundable and in case of rejection of my application, I shall
              not seek refund of the same from HFCL.
            </p>
            <p>
              f) I/We hereby expressly consent to and authorize HFCL to collect,
              use and process my/our personal data for the purposes described in
              this form and pursuant to HFCL Customer Data Privacy Policy.
            </p>
            <p>
              g) We authorize/ give consent to HFCL or its agent, its service
              providers, or any third party, as HFCL may deem fit, to carry out
              CIBIL/other bureau enquiries/reports, to carry out the KYC and
              other requisite checks, share my/our data information (including
              my/our personal data), make references and carry out any other
              third-party vendor checks/verifications relating to information in
              this application form which HFCL considers necessary.
            </p>
            <p>
              h) I/We have read and understood the privacy policy available on
              Hero FinCorp’s website and consent to the processing of my/our
              personal information by HFCL.
            </p>
            <p>
              i) We shall indemnify HFCL, its affiliates, directors, employees,
              representatives, agents from and against any and all losses,
              damages, claims, demands, costs, expenses, or any other loss of
              any kind whatsoever, which HFCL may suffer or incur as a result of
              any action/claim raised by any institutions or any third party for
              making reference, conducting investigations and/or making
              disclosures in terms of the preceding clause.
            </p>
            <p>
              j) I/We have no objection to give the documents required as per
              KYC guidelines issued by RBI.
            </p>
            <p>
              k) No insolvency/bankruptcy/winding up proceeding or recovery suit
              of the outstanding dues/monies whatsoever or for attachment of
              my/our assets or properties and/or criminal proceedings have been
              initiated and/or pending against me/us and we have never been
              adjudicated insolvent by any court or authority.
            </p>
            <p>
              l) I/We hereby confirm that there has/was never been an award or
              an order or proceeding or adverse judgment or decree in a Court
              case involving breach of contract, tax malfeasance or other
              serious misconduct which shall adversely affect my/our ability to
              repay the Loan.
            </p>
            <p>
              m) I/We have never been a defaulter with HFCL or any bank or any
              other financial institution or any non-banking financial company.
            </p>
            <p>
              n) I/We shall keep informed HFCL, in writing, of any change in
              my/our contact information or residential/office address/change in
              job.
            </p>
            <p>
              o) HFCL is authorized to make enquiries with any finance
              company/bank/registered credit bureau etc. for KYC information
              verification, credit risk analysis, or for other related purposes
              that HFCL may deem fit and that my/our privilege of privacy and
              privity of contract with regards the subject matter of my/our
              arrangement is expressly waived. I/We also confirm that HFCL
              reserves the right to retain photograph(s) and document(s)
              submitted.
            </p>
            <p>
              p) I/We will not hold HFCL or its agents or representatives
              responsible under any circumstances whatsoever in case my
              application is rejected.
            </p>
            <p>
              q) I/We confirm having received read & understood the terms and
              conditions of the loan applied for, including the terms relating
              to repayment, interest & other fees and unconditionally agree to
              abide/perform the same & also hereby accept that the terms and
              conditions may be changed by HFCL at any time. I/We also agree to
              abide by all the terms and conditions of all the related
              agreement(s). I/We also confirm that all my questions, doubts and
              queries with regards the loan have been answered to my
              satisfaction and properly explained to me/us; which fact I/We
              shall no question, doubt or dispute at any future date/event.
            </p>
            <p>
              r) I/We confirm & agree that by signing this application form,
              I/We am/are deemed to have accepted, read, understood and agreed
              to be bound by the terms (including amendments thereof) governing
              the loan facility, I/We confirm and agree that I/We have no
              objection to HFCL sending us offers of their products or service
              of its subsidiaries, affiliates or associates companies of HFCL.
            </p>
            <p>
              s) I/We hereby accepts, confirms, and consents for the disclosure
              and sharing of information in relation to my Loan by HFCL to any
              third party including but not limited to private entities,
              government or regulatory bodies or any other body as HFCL may
              decide, from time to time.
            </p>
 
            <p>
              t) The use of above information shall not amount to violation of
              any of my/our privacy or confidentially rights or privileges.
            </p>
            <p>
              u) I/We confirm that no cash/blank cheque has been given by me
              along with this application, nor have I been promised any free
              gifts/inducement to apply for this loan.
            </p>
            <p>
              v) In addition to the above, I/We shall indemnify the HFCL to make
              the loss good in the event of any loss damage that may arise on
              account of false/incorrect declaration by me/us.
            </p>
            <p>
              w) I/We acknowledges that HFCL adopts interest rate/pricing
              methodology based on various risks associated with the type of
              Loan, cost of funds, tenure of the Loan, type & value of security,
              loan amount etc. Further HFCL periodically reviews the rate of
              interest and product features offered by it with those offered by
              its competitors. For a borrower an additional risk premium may be
              included in the pricing based on the credit risk associated with
              the borrower, which is a function of the borrower's credit
              history, credit information, internal rating along with other
              factors like the borrower's source of income, professional
              experience etc. Accordingly, the applicable rate of interest may
              be different for different categories of borrowers and may differ
              from one loan to other loan.
            </p>
            <p>
              x) I/We hereby authorize HFCL representative for processing this
              Loan Application Form with Hero FinCorp Limited on my/our behalf
              and undertake to provide all papers in original required for any
              clarification, to HFCL.
            </p>
            <p>
              y) This is to confirm that I/We have submitted all necessary and
              relevant self-attested documents to process my loan application
              with HFCL.
            </p>
 
            {/* The rest of the terms and conditions content goes here */}
          </div>
        </div>
      )}
 
      <p>
        <label htmlFor="concernCheckboxY">
          <input
            type="checkbox"
            id="concernCheckboxY"
            checked={isCheckedY}
            onChange={handleCheckboxChangeY}
          />
          I/We acknowledge that I/We have read and agreed with the terms and
          conditions
        </label>
      </p>
 
      <button
        className="submit-button"
        onClick={handleSubmit}
        disabled={!isCheckedY || isSubmitting}
        style={{
          backgroundColor: isCheckedY ? "blue" : "gray",
          color: isCheckedY ? "white" : "black",
        }}
      >
        {" "}
        Accept
        {/* {isSubmitting ? "Submitting..." : "Approved"} */}
      </button>
    </div>
  );
};
 
export default ConcerntLinkOMPL;