import React, { useEffect } from 'react';
import './ThankYou.css';
import HeroFincorpLogo from '../assets/hero.png';
import { useNavigate } from 'react-router-dom';
 
function ThankYou() {
  const navigate = useNavigate();
  useEffect(() => {
   
    navigate('/thankyou', { replace: true });
    window.history.pushState(null, '', '/thankyou');
 
 
    const handlePopState = () => {
      navigate('/thankyou', { replace: true });
    };
 
    window.addEventListener('popstate', handlePopState);
 
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
 
  }, [navigate]);
  return (
    <div className="App">
      <header className="App-header">
        <img src={HeroFincorpLogo} alt="Hero Fincorp Logo" className="App-logo" />
        <div className="App-message">
          <div className="Success-box">
            <label className="Checkbox-circle">
              <input type="checkbox" className="Checkbox-input" defaultChecked />
              <span className="Checkbox-icon">✔</span>
            </label>
            <div className="Success-content">
              <span className="Checkbox-label">Success:</span>
              <span className="Success-message">Consent Approved Successfully!</span>
            </div>
          </div>
          <p className="App-congratulations">Congratulations! Your loan application process has started!!</p>
        </div>
      </header>
    </div>
  );
}
 
export default ThankYou;