// import React from 'react';

// const PhoneNumberLink = ({ phoneNumber, label }) => {
//   const phoneLink = `tel:${phoneNumber}`;

//   return (
//     <a href={phoneLink}>{label}</a>
//   );
// };

// export default PhoneNumberLink;
import React from 'react';

const PhoneNumberLink = () => {
  return (
    <div>
      <h2>Phone Number Page</h2>
      <p>This is the phone number page.</p>
    </div>
  );
};

export default PhoneNumberLink;
